import { STATUS } from '~/status'
import { validateIban } from '~/helper'

export default {
  hasUser (state) {
    return !!state.user?.customer
  },
  getUser (state) {
    return state.user
  },
  getCustomer (state) {
    return state.user?.customer
  },
  getKreditsumme (state) {
    return state.user?.customer?.money?.kreditsumme
  },
  getIban (state) {
    return state.user?.customer?.money?.iban
  },
  getAuxmoney (state) {
    return state.user?.customer?.auxmoney || null
  },
  hasGermanIban (state) {
    return state.user?.customer?.money?.iban?.startsWith('DE')
  },
  hasValidIban (state) {
    return validateIban(state.user?.customer?.money?.iban)
  },
  getKdnr (state) {
    return state.user?.kdnr
  },
  kdnrEven (state) {
    return state.user?.kdnr % 2 === 0
  },
  rentner (state) {
    const birthdate = state.user?.customer?.details?.geburtstag
    if (birthdate) {
      const age = new Date().getFullYear() - new Date(birthdate).getFullYear()
      return age >= 69
    }

    return false
  },

  overFifty (state) {
    const birthdate = state.user?.customer?.details?.geburtstag
    if (birthdate) {
      const age = new Date().getFullYear() - new Date(birthdate).getFullYear()
      return age >= 50
    }

    return false
  },
  vertragsDaten (state) {
    return state.user?.customer?.vertragsdaten
  },
  getBerufsgruppen (state) {
    return state.user?.values?.berufsgruppe
  },
  hasOwnWebid (state) {
    return state.user?.customer?.vertragsdaten?.own_webid_ident || false
  },
  getUploads (state) {
    return state.user?.uploads || []
  },
  getDocuments (state) {
    return state.user?.documents || []
  },
  isDoppelt (state) {
    const lookup = [STATUS.DOPPELT]

    return lookup.includes(state.user?.status?.id)
  },
  signageNeeded (state) {
    const targetDocument = state.user?.documents?.find(doc => doc.udid === 5)
    return (
      targetDocument && targetDocument.need === 1 && targetDocument.have === 0
    )
  },
  finanzUebersichtNeeded (state) {
    const targetDocument = state.user?.documents?.find(
      doc => doc.udid === 43
    )
    return (
      targetDocument && targetDocument.need === 1 && targetDocument.have === 0
    )
  },
  baubeschreinbungNeeded (state) {
    const targetDocument = state.user?.documents?.find(
      doc => doc.udid === 40
    )
    return (
      targetDocument && targetDocument.need === 1 && targetDocument.have === 0
    )
  },
  getVersicherungGestartet (state) {
    return state.user?.versicherungs_vergleich_gestartet || false
  },
  showRsv (state) {
    return state.user?.show_rsv || false
  },
  showMa (state) {
    return state.user?.show_mitantragsteller || false
  },
  showKreditsummeAendern (state) {
    return state.user?.show_kreditsumme_aendern || false
  },
  getExistenzGestartet (state) {
    return state.user?.existenzschutz_gestartet || false
  },
  getExistenzschutzHigh (state) {
    return false // state.user?.existenzschutz_high || false
  },
  getExistenzschutzVariant (state) {
    return state.user?.existenzschutz_variant || 'komfort'
  },
  getFinanzSchutzbriefGestartet (state) {
    return state.user?.finanzschutzbrief_gestartet || false
  },
  needEinkommensnachweis (state) {
    const statusAusbzehaltung = state.user?.status?.id === 11

    if (statusAusbzehaltung) {
      return false
    }

    return state.user?.needEinkommensnachweis || false
  },
  einkommensnachweisUploaded (state) {
    const serverAnswer = state.user?.needEinkommensnachweis

    const upload = state.user?.uploads?.find(doc =>
      doc.filename.includes('Einkommensnachweis')
    )

    const targetDocument = state.user?.documents?.find(doc => doc.udid === 7)

    return (
      (targetDocument &&
        targetDocument.need === 1 &&
        targetDocument.have === 1) ||
      !serverAnswer ||
      upload
    )
  },

  sepaNeeded (state) {
    const sepaRequest = state.user?.documents?.find(
      doc => doc.name.includes('Unterschrift SEPA Mandat') || doc.udid === 99
    )

    return sepaRequest && sepaRequest.need === 1 && sepaRequest.have === 0
  },

  worthDigital (state) {
    const showIn = [
      STATUS.ESSEN_GENEHMIGT,
      STATUS.VERTRAGZUMKUNDEN,
      STATUS.SALESFORCE
    ]

    return showIn.includes(state.user?.status?.id)
  },

  allowDocUpload (state) {
    return state.user?.allowDocUpload || false
  },

  getVertrag (state) {
    const has = state.user?.uploads?.find(({ filename }) => {
      return (
        filename.includes('Vertrag.pdf') ||
        filename.includes('Ihr Vertragsexemplar zur Bank.pdf')
      )
    })

    return has || null
  },

  getKundenexemplar (state) {
    const has = state.user?.uploads?.find(({ filename }) => {
      return filename.includes('Ihr Kundenexemplar zum Abheften.pdf')
    })

    return has || null
  },

  getOldVertraege (state) {
    return state.user?.oldVertraege || null
  },

  showEinkommensnachweis (state) {
    const lookup = [
      STATUS.PRUFUNG,
      STATUS.ESSEN_BEARBEITUNG,
      STATUS.FEHLER,
      STATUS.SALESFORCE
    ]

    return lookup.includes(state.user?.status?.id)
  },
  hasIban (state) {
    return state.user?.customer?.money?.iban !== null
  },
  hasMitantragssteller (state) {
    return state.user?.customer?.partner?.p_vorname !== null
  },
  postIdentLink (state) {
    if (state.user?.postident) {
      return `https://postident.deutschepost.de/identportal/?vorgangsnummer=${state.user.postident}`
    }
    return `https://postident.credimaxx.eu/?kdnr=${state.user.kdnr}&email=${state.user.customer.details.email}`
  },
  currentPercentage (state) {
    return state.user?.status?.progress
  },
  currentProgress (state) {
    return `${state.user?.status?.progress ?? 0}%`
  },
  getToken (state) {
    return state.tokenWithoutBearer
  },
  /**
   * Status Getters
   */
  getStatus (state) {
    return state.user?.status
  },
  getStatusId (state) {
    return state.user?.status?.id
  },
  isSelbstaendig (state) {
    return state.user?.status?.id === STATUS.SELBSTSTANDIGE
  },
  isVertragAbgelehnt (state) {
    return state.user?.status?.id === STATUS.VERTRAGABGELEHNT
  },
  isAuxmoney (state) {
    const lookup = [
      STATUS.AUXMONEYANFRAGE,
      STATUS.AUXMONEYINBEARBEITUNG,
      STATUS.AUXMONEYQS,
      STATUS.AUXMONEYVERTRAGERSTELLT,
      STATUS.AUXMONEYAUSBEZAHLT,
      STATUS.AUXMONEYABGELEHNT,
      STATUS.AUXMONEYFEHLER
    ]

    return lookup.includes(state.user?.status?.id)
  },
  isAuxmoneyAnfrage (state) {
    const lookup = [
      STATUS.AUXMONEYANFRAGE
    ]

    return lookup.includes(state.user?.status?.id)
  },
  isEssenPruefung (state) {
    return state.user?.status?.id === STATUS.PRUFUNG
  },

  isNegativSchufa (state) {
    return state.user?.status?.id === STATUS.NEGATIVESCHUFA
  },
  isAbgewiesen (state) {
    const lookup = [
      STATUS.PAPIERKORB,
      STATUS.VERTRAGABGELEHNT,
      STATUS.SCHWEIZ_ABGELEHNT,
      // STATUS.NEGATIVER_SPEEDCHECK,
      STATUS.NEGATIVESCHUFA
    ]

    return lookup.includes(state.user?.status?.id)
  },
  isKeineReaktion (state) {
    return state.user?.status?.id === STATUS.KEINE_REAKTION_21
  },
  isPotentialSpaeter (state) {
    return state.user?.status?.id === STATUS.POTENTIAL_SPAETER
  },
  isDigitalerVertrag (state) {
    return (
      state.user?.status?.id === STATUS.DIGITALERVERTRAG ||
      state.user?.customer?.allow_web_id
    )
  },
  toOldForDigital (state) {
    // date in format Y-m-d
    const exportStatus = state.user?.customer?.es || null

    if (!exportStatus) {
      return false
    }

    // if exportStatus is older than 2 months and Status is VERTRAGZUMKUNDEN
    const today = new Date()
    const exportDate = new Date(exportStatus)
    const diff = today - exportDate
    const diffInDays = diff / (1000 * 60 * 60 * 24)

    return (
      (diffInDays > 60 && state.user?.status?.id === STATUS.VERTRAGZUMKUNDEN) || diffInDays > 90
    )
  },
  showKundenexemplar (state) {
    const hasFile = state.user?.uploads?.find(({ filename }) => {
      return filename.includes('Ihr Kundenexemplar zum Abheften.pdf')
    })

    const hasVertrag = state.user?.uploads?.find(({ filename }) => {
      return filename.includes('Ihr Vertragsexemplar zur Bank.pdf')
    })

    if (
      state.user?.status?.id === STATUS.VERTRAGABGELEHNT ||
      state.user?.status?.id === STATUS.KREAKTIONRUCKTRITT ||
      state.user?.status?.id === STATUS.KREDITWIRDAUSBEZAHLT
    ) {
      return false
    }

    return ((!hasFile && state.user?.customer?.vertragsdaten?.webid_qes) || hasVertrag || state.user?.status?.id === STATUS.VERTRAGZUMKUNDEN || state.user?.status?.id === STATUS.SALESFORCE || state.user?.status?.id === STATUS.VERTRAGSEINGANG)
  },
  hasVertrag (state) {
    return state.user?.customer?.vertragsdaten?.has_vertrag || false
  },
  signedDitalContract (state) {
    return state.user?.customer?.vertragsdaten?.webid_qes || false
  },
  partnerNeedsIdent (state) {
    return state.user?.customer?.needs_partner_ident || false
  },
  isDuratio (state) {
    const lookup = [STATUS.ALTERNATIVEBANKEN, STATUS.IMMO_ANDURATIOUBERGEBEN]

    return lookup.includes(state.user?.status?.id)
  },
  isDuratioVertrag (state) {
    const lookup = [STATUS.ALTERNATIVEBANKEN, STATUS.IMMO_ANDURATIOUBERGEBEN]

    return (
      lookup.includes(state.user?.status?.id) &&
      state.user?.customer?.allow_duratio_vertrag
    )
  },
  isDuratioAbgelehnt (state) {
    const lookup = [STATUS.ALTERNATIVEBANKEN, STATUS.IMMO_ANDURATIOUBERGEBEN]

    const lookup2 = [
      'Negativ extern',
      'Negativ intern',
      'Kunde tritt zurück',
      'Abgelehnt Bank'
    ]

    return (
      lookup.includes(state.user?.status?.id) &&
      lookup2.includes(state.user?.customer?.duratio_status?.status)
    )
  },
  getDuratioContractLink (state) {
    return state.user?.customer?.duratio_status?.contract_link
  },
  getDuratioStatus (state) {
    return state.user?.customer?.duratio_status?.status
  },
  positivDuratioStatus (state) {
    const lookup = [
      'In Bearbeitung',
      'Genehmigt',
      'Vertrag zum Kunden',
      'Vertrag zur Bank',
      'Unterlageneingang',
      'Abgeschlossen'
    ]

    return lookup.includes(state.user?.customer?.duratio_status?.status)
  },

  negativDuratioStatus (state) {
    const lookup = [
      'Abgelehnt Bank',
      'Negativ intern',
      'Negativ extern',
      'Abgelehnt intern',
      'Kunde tritt zurück'
    ]

    return lookup.includes(state.user?.customer?.duratio_status?.status)
  },
  isAusbezahlt (state) {
    return false // state.user?.status?.id === STATUS.KREDITWIRDAUSBEZAHLT
  },
  neuAnfrageMoeglich (state) {
    const lookup = [STATUS.NEGATIVESCHUFA, STATUS.KREAKTIONRUCKTRITT]

    if (state?.user?.customer?.had_contract) {
      return false
    }

    return lookup.includes(state.user?.status?.id)
  },
  zurueckAndHadContract (state) {
    return (state.user?.status?.id === STATUS.KREAKTIONRUCKTRITT && state.user.customer.had_contract) || false
  },
  isZurueckgetreten (state) {
    return state.user?.status?.id === STATUS.KREAKTIONRUCKTRITT
  },
  getName (state) {
    return `${state.user?.firstname} ${state.user?.lastname}`
  },
  getEmail (state) {
    return state.user?.customer?.details?.email
  },
  hideVersicherung (state) {
    return (
      state.user?.hide_versicherung || state.user?.alte_versicherung || false
    )
  },
  hasOldVersicherung (state) {
    return state.user?.alte_versicherung || false
  },
  topInsurance (state) {
    return state.topInsurance
  },
  directInsurance (state) {
    return state.directInsurance || false
  },
  skagPercent (state) {
    return state.user?.skag_percentage
      ? parseFloat(state.user?.skag_percentage)
      : 0
  },
  olderThanFourYears (state) {
    return state.user?.olderThanFourYears || false
  },
  getPath (state) {
    return state.path
  },
  hasKids (state) {
    return (
      state.user?.customer?.details?.kinder !== null &&
      state.user?.customer?.details?.kinder !== 0
    )
  },
  hasSaaleBullsTickets (state) {
    return state.user?.saalebulls_tickets !== null || false
  },
  allowEngelVoelkers (state) {
    return state.user?.customer?.engel_voelkers?.allow || false
  },
  exportedEngelVoelkers (state) {
    return state.user?.customer?.engel_voelkers?.exported || false
  },

  allowPfando (state) {
    return state.user?.customer?.pfando?.allow || false
  },
  exportedPfando (state) {
    return state.user?.customer?.pfando?.exported || false
  },
  allowEpostfach (state) {
    return state.user?.customer?.allow_epostfach || false
  },
  forceWebId (state) {
    return state.user?.customer?.vertragsdaten?.force_webid || false
  },
  noExport (state) {
    return state.user?.customer?.no_export || false
  },
  existenschutzDbl (state) {
    return state.user?.existenschutz_dbl || false
  },
  getShowEigentumVVI (state) {
    return state.user?.show_eigentum_vvi || false
  },
  allowFinapi (state) {
    return state.user?.customer?.allow_finapi || false
  },
  getEigentum (state) {
    return state.user?.customer?.eigentum || null
  }
}
